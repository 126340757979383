<template>
    <div class="qa-detail-page">
        <full-loading ref="fullLoading"></full-loading>
        <page-title :title="info.title||''" style="border: none;" v-if="info">
            <template slot="info">
                <div class="more-info">
                    <el-popover trigger="hover">
                        <el-form label-width="80px" size="mini" class="more-info-popover">
                            <el-form-item :label="$t('tools.tools_qaDetail_page_form_item_label_1')" v-if="LANGUAGE_DICT&&info">
                                {{LANGUAGE_DICT[info.sourceLang].shortName}} <i class="el-icon-arrow-right"></i> {{LANGUAGE_DICT[info.targetLang].shortName}}
                            </el-form-item>
                            <el-form-item :label="$t('tools.tools_qaDetail_page_form_item_label_2')">{{total}} {{$t('tools.tools_qaDetail_page_tips_1')}}</el-form-item>
                            <el-form-item :label="$t('tools.tools_qaDetail_page_form_item_label_3')">{{info.createTime}}</el-form-item>
                        </el-form>
                        <svg-icon name="ib-info" class-name="color-555" slot="reference"></svg-icon>
                    </el-popover>
                </div>
            </template>
            <template slot="tools">
                <div style="width:160px;">
                    <el-input :placeholder="$t('tools.tools_qaDetail_page_input_placeholder_1')" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
                <el-button type="primary" size="small" round icon="el-icon-download" v-permission="`tools:check:detailRouter:download`" :loading="downloadLoading" @click="downloadResult" style="margin-left:10px;">{{$t('tools.tools_qaDetail_page_btn_1')}}</el-button>
            </template>
        </page-title>
       <!--  <div class="page-tools-content" style="margin-bottom:0; padding-top: 0px;">
            <div class="left">
                <el-button type="primary" size="small" round icon="el-icon-download" :loading="downloadLoading" @click="downloadResult">下载</el-button>
            </div>
            <div class="right">
                <div style="width:220px;">
                    <el-input placeholder="输入关键词" 
                        size="small" round suffix-icon="el-icon-search" maxlength="100" clearable
                        v-model="searchForm.kw" @input="inputSearchEvent"></el-input>
                </div>
            </div>
        </div> -->
        <el-alert type="info" center effect="dark" :closable="false">
            <template slot="title">
                <span style="margin-left:10px;font-weight:400;font-size:12px;" v-if="info">
                    <span style="margin-right:20px;">{{$t('tools.tools_qaDetail_page_tips_2')}} <span style="color:red;">{{(info.aiCount||0) + (info.basicCount||0)}}</span> {{$t('tools.tools_qaDetail_page_tips_3')}}</span>{{$t('tools.tools_qaDetail_page_tips_4')}}
                </span>
            </template>
        </el-alert>
        <el-table :data="list" style="width:100%;">
          <el-table-column type="index" :label="$t('tools.tools_qaDetail_page_table_column_label_1')" width="70"></el-table-column>
          <el-table-column :label="$t('tools.tools_qaDetail_page_table_column_label_2', {lang: info && LANGUAGE_DICT && LANGUAGE_DICT[info.sourceLang] && LANGUAGE_DICT[info.sourceLang].name})">
              <template slot-scope="scope">
                <div :lang="info && info.sourceLang" v-html="scope.row.sourceText"></div>
              </template>
          </el-table-column>
          <el-table-column>
              <template slot="header">
                  <span>{{$t('tools.tools_qaDetail_page_table_column_label_3',{lang: info && LANGUAGE_DICT && LANGUAGE_DICT[info.targetLang] && LANGUAGE_DICT[info.targetLang].name})}}</span>
              </template>
              <template slot-scope="scope">
                <div :lang="info && info.targetLang" v-html="scope.row.targetText"></div>
              </template>
          </el-table-column>
          <el-table-column :label="$t('tools.tools_qaDetail_page_table_column_label_4')">
              <template slot-scope="scope">
                <div class="qa-result-content" v-html="scope.row.problemsHtml"></div>
              </template>
          </el-table-column>
        </el-table>
        <div class="pagination-content" v-if="list.length > 0 && total > pageSize">
            <el-pagination 
                background 
                @current-change="handleCurrentChange"
                layout="total, prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { Base64 } from 'js-base64';
import FullLoading from '@/components/FullLoading';
import fileDownload from 'js-file-download';
import config from '@/utils/config';
import { toolsApi, commonApi } from '@/utils/api';
import PageTitle from '@/components/PageTitle';
import AlertDialog from '@/components/AlertDialog';
export default {
    components:{PageTitle, AlertDialog, FullLoading},
    data(){
        return {
            downloadLoading:false,
            info:null,
            dialog:{
                show:false,
            },
            searchForm:{
                kw:''
            },
            searchTimer: null,
            activeTab:'all',
            list:[],
            total:0,
            pageSize:30,
            pageNumber:1,
            pollingTimer: null,
        }
    },
    computed: {
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    methods:{
        initPage(type){
            let url = `${toolsApi.queryQAResultlist}?taskId=${this.$route.query.id}&pageNo=${this.pageNumber}&pageSize=${this.pageSize}&keyword=${encodeURI(this.searchForm.kw)}`;
            if(type !== 'polling'){
                this.$refs.fullLoading.setShow();
            }
            this.$ajax.get(url).then(res=>{
                if (res.status === 200) {
                    let tmpList = [];
                    res.data.pager.records.map(item=>{
                        tmpList.push({
                            ...item,
                            problemsHtml: item.problems?`<p>`+item.problems.split(/\n/g).join('</p><p>')+`</p>`:'',
                            // basicHtml: item.basicProblems?`<p>`+item.basicProblems.split(/\n/g).join('</p><p>')+`</p>`:'',
                        })
                    })
                    this.list = tmpList;
                    this.info = res.data.task;
                    this.total = res.data.pager.total;
                    if(this.info.aiCount > 0){
                        if(this.pollingTimer){
                            clearInterval(this.pollingTimer);
                            this.pollingTimer = null;
                        }
                    }
                }
            }).finally(()=>{
                if(type !== 'polling'){
                    this.$refs.fullLoading.setHide();
                }
            });

        },
        handleCurrentChange(val){
            this.pageNumber = val;
            this.initPage();
        },
        inputSearchEvent(value){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                this.pageNumber = 1;
                self.initPage();
            },500);
        },
        tabItemClick(index,data){
            this.activeTab = data.key;
        },
        downloadResult(){
            this.downloadLoading = true;
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                title: this.info.title,
                metadataIdList: [this.$route.query.id],
                metadataType: 'TOOLKIT_QA',
                moreOptions: {
                    filterType: null,
                },
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(()=>{
                this.downloadLoading = false;
            })
        },
    },
    mounted(){
        let self = this;
        this.initPage();
        self.pollingTimer = setInterval(()=>{
            self.initPage('polling');
        },30000);
    },
    destroyed() {
        if(this.pollingTimer){
            clearInterval(this.pollingTimer);
            this.pollingTimer = null;
        }
    },
}
</script>
<style lang="scss" scoped>
.qa-detail-page{
    .qa-type-title{
        font-weight: 600;
        font-size: 12px;
        margin-bottom:5px;
    }
    .qa-result-content{
        ::v-deep p{
            font-size: 14px;
            padding:5px 0;
        }
    }
    .more-info {
        font-size: 20px;
    }
}
</style>
<style lang="scss">
.qa-detail-page{
    .el-alert--info.is-dark {
        background-color: #f7efe0;
        color: #000000;
        border-radius: 0px;
    }
    .el-alert.is-dark .el-alert__closebtn {
        color: #000000;
        font-size:16px;
        margin-top:-2px;
    }
}
</style>
